import styled from '@emotion/styled';
import { Select } from 'antd';

export function isNotEmptyValue(
  value: null | boolean | number | string | string[],
) {
  if (Array.isArray(value)) {
    return Boolean(value.length);
  }

  if (typeof value === 'boolean') {
    return Boolean(String(value));
  }

  return Boolean(value);
}

interface ISelectStyleProps {
  disabled?: boolean;
  error?: string;
  value: null | boolean | number | string | string[];
  mode?: 'multiple' | 'tags';
}

// todo: fix color variables
export const StyledSelect = styled(Select)<ISelectStyleProps>`
  --color-empty-select-elements: ${({ disabled }) =>
    disabled
      ? 'var(--color-button-disable-text)'
      : 'var(--color-field-filter-placeholder)'};
  --color-select-elements: ${({ value, disabled }) =>
    isNotEmptyValue(value) && !disabled
      ? 'var(--color-field-primary)'
      : 'var(--color-empty-select-elements)'};

  width: 100%;

  &&&&&& {
    & > div:first-of-type {
      border: 1px solid
        ${({ error }) =>
          error
            ? 'var(--color-status-error-2)'
            : 'var(--color-select-elements)'};
    }
  }

  &&&&& {
    &:hover .ant-select-selector,
    &:active .ant-select-selector {
      border-color: ${({ error }) => error && 'var(--color-status-error-2)'};
    }
  }

  .ant-select-selection-item {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: ${({ mode }) =>
      mode === 'multiple'
        ? 'var(--color-text-primary-2)'
        : 'var(--color-text-primary)'};
    background-color: ${({ mode, disabled }) =>
      mode === 'multiple' &&
      !disabled &&
      'var(--color-background-additional-3)'};
    border-color: ${({ mode, disabled }) =>
      mode === 'multiple' &&
      !disabled &&
      'var(--color-background-additional-3)'};
  }

  .ant-select-selection-item-remove {
    align-self: center;
    color: var(--color-elements-additional);

    &:hover {
      color: var(--color-elements-type-secondary);
    }
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
    color: var(--color-select-elements);
    inset-inline-end: 22px;
  }

  .ant-select-arrow {
    color: var(--color-select-elements);
  }

  .ant-select-dropdown {
    padding: 0;

    .ant-select-item-option-active,
    .ant-select-item-option-selected {
      background-color: var(--color-background-additional-2);
    }
  }

  .rc-virtual-list-scrollbar-thumb {
    background-color: var(--color-elements-additional-2) !important;
  }

  .rc-virtual-list-scrollbar-vertical {
    width: 4px !important;
    right: 2px !important;
  }

  .ant-select-item-option-content {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: var(--color-text-primary-2);
  }
`;
