export enum MessageType {
  REQUIRED = 'required',
  NUMBER = 'number',
  MIN = 'min',
  MAX = 'max',
  MIN_NUMBER = 'min_number',
  MAX_NUMBER = 'max_number',
  INTEGER = 'integer',
  INVALID_FORMAT = 'invalid_format',
  INVALID_JSON = 'invalid_json',
}

export function getZodMsg(
  messageType: MessageType,
  label?: string,
  additionalParam?: number | string,
) {
  const messages = {
    required: `${label} is required field`,
    number: `${label} must be a number`,
    min: `${label} must be at least ${additionalParam} characters`,
    max: `${label} must be at most ${additionalParam} characters`,
    min_number: `${label} must be at least ${additionalParam}`,
    max_number: `${label} must be at most ${additionalParam}`,
    integer: `${label} must be an integer`,
    invalid_format:
      'Please use only latin characters, digits, and special symbols',
    invalid_json: 'Invalid JSON',
  };

  return messages[messageType];
}
