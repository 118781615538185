import { IBaseApiRequest } from '@api';
import { IPaginated, ISortable, IContact, IContactsFilters } from '@entities';
import { getSorterParamsForApi, insertIfObj } from '@functions';
import { AxiosResponse } from 'axios';

interface IGetAll extends IBaseApiRequest, IPaginated, ISortable {
  baasClientId: string;
  filters?: IContactsFilters;
}
export async function getAll({
  api,
  offset,
  limit,
  attribute,
  direction,
  baasClientId,
  filters,
}: IGetAll) {
  let params = {
    offset,
    limit,
    ...getSorterParamsForApi({ attribute, direction }),
    ...insertIfObj(Boolean(filters?.clientId), { clientId: filters?.clientId }),
    ...insertIfObj(Boolean(filters?.search), {
      search: filters?.search,
    }),
    ...insertIfObj(Boolean(filters?.type), {
      'q[kind_eq]': filters?.type,
    }),
    ...insertIfObj(Boolean(filters?.paymentMethods?.length), {
      'q[payment_kind_in]': filters?.paymentMethods?.join(','),
    }),
  };

  let response = (await api.get(`bank/${baasClientId}/contacts`, {
    params,
  })) as AxiosResponse<{
    success: boolean;
    errorCode: number;
    error: string;
    message: string;
    data: {
      data: IContact[];
      meta: {
        offset: number;
        limit: number;
        total: number;
      };
    };
  }>;

  return response.data.data;
}

interface IGetOne extends IBaseApiRequest {
  id: string;
  baasClientId: string;
}
export async function getOne({ api, id, baasClientId }: IGetOne) {
  let response = (await api.get(
    `bank/${baasClientId}/contacts/${id}`,
  )) as AxiosResponse<{
    data: IContact;
  }>;

  return response.data.data;
}
