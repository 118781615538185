import { useCallback, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';
import * as z from 'zod';
import { showMessage } from '@components';
import { getZodMsg, MessageType } from '@functions';
import { useApi } from '@hooks';

const validationSchema = z.object({
  pricingPlan: z
    .string()
    .min(1, getZodMsg(MessageType.REQUIRED, 'Pricing Plan'))
    .default(''),
});

type FValues = z.infer<typeof validationSchema>;

export interface IUseChangePricingPlanProps {
  clientId?: string;
  tariffId?: string;
  onClose: () => void;
}

export function useChangePricingPlan({
  onClose,
  clientId,
  tariffId,
}: IUseChangePricingPlanProps) {
  const { api, client: apiClient } = useApi();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: FValues) => {
      const defaultError =
        'Sorry, something went wrong. Please try again later.';
      try {
        setLoading(true);

        const result = {
          success: true,
          error: '',
        };

        if (result.success) {
          showMessage({
            text: 'Pricing plan has been successfully updated',
          });
          mutate('');
          onClose();
        } else {
          showMessage({ type: 'error', text: result.error || defaultError });
        }
      } catch (e) {
        showMessage({ type: 'error', text: defaultError });
      } finally {
        setLoading(false);
      }
    },
    [apiClient, onClose],
  );

  const form = useForm<FValues>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      pricingPlan: tariffId,
    },
    mode: 'onTouched',
  });

  const isValid = useMemo(
    () => form.formState.isValid && form.formState.isDirty,
    [form.formState.isDirty, form.formState.isValid],
  );

  return {
    form,
    isLoading,
    isValid,
    onSubmit: form.handleSubmit(handleSubmit),
  };
}
