import { notification } from 'antd';
import { StyledText } from './index.styles';

interface IShowMessage {
  type?: 'success' | 'error';
  text: string;
  duration?: number;
}

// To change styles, see GlobalStylesProvider
export function showMessage({
  type = 'success',
  text,
  duration = 3,
}: IShowMessage) {
  const style = {
    width: 'max-content',
    padding: '12px 24px',
    backgroundColor:
      type === 'success'
        ? 'var(--color-status-success)'
        : 'var(--color-status-error)',
    borderRadius: '8px',
    boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.1)',
  };

  notification.config({
    placement: 'topRight',
    top: 70,
    duration,
  });

  if (type === 'success') {
    notification.success({
      message: <StyledText type={type}>{text}</StyledText>,
      style,
      icon: undefined,
      closeIcon: false,
    });
  }

  if (type === 'error') {
    notification.error({
      message: <StyledText>{text}</StyledText>,
      style,
      icon: undefined,
      closeIcon: false,
    });
  }
}
