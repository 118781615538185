import styled from '@emotion/styled';
import { createShouldForwardProp } from '@functions';
import { Typography } from 'antd';

interface IStyledTextProps {
  type?: 'success' | 'error';
}

const shouldForwardProp = createShouldForwardProp('type');

export const StyledText = styled(
  Typography.Text,
  shouldForwardProp,
)<IStyledTextProps>`
  &&& {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: ${({ type }) =>
      type === 'success'
        ? 'var(--color-text-success)'
        : 'var(--color-text-error)'};
  }
`;
