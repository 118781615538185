import { Alert, Form, Input, InputNumber } from 'antd';
import styled from '@emotion/styled';
import { createShouldForwardProp } from '@functions';

interface IStyledInputProps {
  isEmpty: boolean;
}

let shouldForwardProp = createShouldForwardProp(['isEmpty']);

export let StyledAlert = styled(Alert)`
  &&& {
    margin-top: 16px;
  }
`;

export let StyledInput = styled(Input, shouldForwardProp)<IStyledInputProps>`
  &&& {
    min-height: 40px;
    border: 1px solid
      ${({ isEmpty }) =>
        isEmpty
          ? 'var(--color-field-default-stroke)'
          : 'var(--color-field-filled-stroke)'};
    color: ${({ isEmpty }) =>
      isEmpty
        ? 'var(--color-field-filter-placeholder)'
        : 'var(--color-text-primary)'};
    border-radius: 6px;

    ::placeholder {
      font-size: 12px;
      color: var(--color-field-filter-placeholder);
    }
    &:disabled {
      border: 1px solid var(--color-field-default-stroke);
      background-color: var(--color-field-disabled-bg);
      color: var(--color-text-gray-secondary);
    }
  }
`;

export let StyledPasswordInput = styled(Input.Password)`
  &&& {
    min-height: 40px;
    border: 1px solid var(--color-field-primary);
    border-radius: 6px;
    color: var(--color-field-primary);
  }
`;

export let StyledNumberInput = styled(InputNumber)`
  &&& {
    width: 100%;
    color: var(--color-field-primary);

    input {
      font-family: 'Poppins', sans-serif;
      color: var(--color-field-primary);

      ::placeholder {
        font-size: 12px;
        color: var(--color-field-filter-placeholder);
      }
    }
  }
`;

export let StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    padding: 0 0 4px;

    & > label {
      font-size: 12px;
      font-weight: 500;
      line-height: 1.83;
      color: var(--color-field-title);
    }
  }
`;
