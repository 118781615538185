import isPropValid from '@emotion/is-prop-valid';

export function createShouldForwardProp(propsName: string | string[]) {
  return {
    shouldForwardProp: (prop: string) => !propsName.includes(prop),
  };
}

export function createCustomShouldForwardProp(excludeForwardProps: string[]) {
  return (propName: string) =>
    isPropValid(propName) && !excludeForwardProps.includes(propName);
}
