import { Layout, Menu, Typography } from 'antd';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { createShouldForwardProp } from '@functions';
import { Logo } from '../Logo';
import { Avatar } from './Avatar';

interface StyledHeaderProps {
  type?: 'primary' | 'secondary';
}

interface IMenuItemsStylesProps {
  isActive: boolean;
  isSingle?: boolean;
  collapsed: boolean;
}

let shouldForwardProp = createShouldForwardProp([
  'textColor',
  'isActive',
  'isSingle',
  'collapsed',
  'type',
]);

export let StyledLayout = styled(Layout)`
  &&& {
    height: 100vh;
    width: 100%;

    .ant-layout-sider-children {
      height: 100%;
      overflow: auto;
    }
  }
`;

export let StyledHeader = styled(
  Layout.Header,
  shouldForwardProp,
)<StyledHeaderProps>`
  &&& {
    background-color: ${({ type = 'primary' }) =>
      type === 'primary'
        ? 'var(--color-background-additional-light)'
        : 'var(--color-background-additional-2)'};
    box-shadow: inset 0px -1px 0px var(--color-background-additional-6);
    padding: 0 40px 0 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export let StyledLogo = styled(Logo)`
  cursor: pointer;
`;

export let StyledLogoWithTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export let StyledLeftPart = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 103px;
  place-items: center;
`;
export let StyledEnvironment = styled.div`
  color: var(--golden-purple-8, #391085);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.14px;
`;

export let StyledLogoTitle = styled(Typography.Text)`
  &&& {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1375;
    text-transform: uppercase;
    color: var(--color-text-primary);
  }
`;

export let StyledInnerLayout = styled(Layout)``;

export let StyledTopMenu = styled.div`
  &&& {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
  }
`;

export let StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 12px;
  border: 1px solid transparent;
  border-radius: 2px;
  transition: background-color 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  cursor: pointer;

  & > span {
    cursor: pointer;
    font-size: 16px;
    color: var(--color-elements-additional-3);
    transition: color 0.3s cubic-bezier(0.45, 0, 0.55, 1);
  }

  &:hover {
    background-color: var(--color-background-additional-2);

    & > span {
      color: var(--color-background-additional-5);
    }
  }

  &:active {
    border: 1px solid var(--color-button-focused-stroke);
  }
`;

export let StyledAvatar = styled(Avatar)`
  margin: 0 12px;
`;

export let StyledLeftMenu = styled(Menu)`
  &&& {
    li {
      div {
        //padding-left: 16px !important;
      }
    }
  }
`;

export let StyledCollapseElement = styled.div`
  cursor: pointer;
  position: absolute;
  top: calc(50% - 66px / 2);
  right: -14px;
  width: 14px;
  height: 66px;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='66' viewBox='0 0 14 66' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0L14 8V58L0 66V0Z' fill='white'/%3E%3C/svg%3E%0A");

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--color-elements-primary);
  font-size: 12px;
`;

export let StyledLeftSider = styled(Layout.Sider)`
  &&& {
    background: var(--color-background-primary);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    position: relative;
  }
`;

export let StyledContentLayout = styled(Layout)`
  &&& {
    background-color: var(--color-background-secondary);
    overflow: auto;
  }
`;

export let ContentLayoutContainer = styled.div`
  padding: 20px 40px;
`;

export let StyledContent = styled(Layout.Content)`
  padding-top: 8px;
`;

export let StyledSubMenuItem = styled(
  Menu.SubMenu,
  shouldForwardProp,
)<IMenuItemsStylesProps>`
  &&& {
    & > div {
      width: 100%;
      margin-inline: 0;
      border-radius: 0;
      font-size: 12px;
      font-weight: 400;
      color: var(--color-text-main-elements);

      &:hover {
        background-color: var(--color-background-additional-light);
      }

      ${({ isActive, collapsed }) =>
        isActive &&
        css`
          background-color: var(--color-background-additional-dark);
          ${collapsed &&
          css`
            &::after {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 3px;
              height: 100%;
            }
          `}
        `}
    }

    .ant-menu-submenu-arrow {
      color: var(--color-elements-additional-3);
    }

    .ant-menu-submenu-title {
      padding-inline: ${({ collapsed }) => collapsed && 'calc(50% - 8px)'};
    }
  }
`;

export let StyledMenuItem = styled(
  Menu.Item,
  shouldForwardProp,
)<IMenuItemsStylesProps>`
  &&& {
    width: 100%;
    margin-inline: 0;
    border-radius: 0;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text-main-elements);

    & > span {
      color: var(--color-elements-additional-3);
    }

    padding-inline: ${({ isSingle, collapsed }) =>
      isSingle && collapsed && 'calc(50% - 8px)'};

    &:hover {
      background-color: ${({ isActive }) =>
        !isActive && 'var(--color-background-additional-light) !important;'};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        background-color: var(--color-background-additional-dark);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 3px;
          height: 100%;
          background-color: var(--color-elements-primary);
        }
      `}
  }
`;

export let StyledCollapsedMenuHeader = styled(Menu.Item)`
  &&& {
    width: 100%;
    margin: 0;
    font-size: 10px;
    font-weight: 500;
    color: var(--color-text-light);
    background-color: var(--color-background-additional-5);
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    pointer-events: none;
    cursor: default;
  }
`;
