import { Tag } from 'antd';
import styled from '@emotion/styled';
import { createShouldForwardProp } from '@functions';
import { ITagProps } from '.';

let shouldForwardProp = createShouldForwardProp(['textColor']);

export let TagWrapper = styled.div`
  display: flex;
`;

export let StyledTag = styled(Tag, shouldForwardProp)<ITagProps>`
  &&& {
    margin-inline-end: 0;
    font-size: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    color: ${({ textColor }) => textColor};
  }
`;
