import styled from '@emotion/styled';
import { Select } from 'antd';

export function isNotEmptyValue(
  value: null | boolean | number | string | string[],
) {
  if (Array.isArray(value)) {
    return Boolean(value.length);
  }

  if (typeof value === 'boolean') {
    return Boolean(String(value));
  }

  return Boolean(value);
}

interface ISelectStyleProps {
  disabled?: boolean;
  value: null | boolean | number | string | string[];
  mode?: 'multiple' | 'tags';
}

export let StyledSelect = styled(Select)<ISelectStyleProps>`
  --color-empty-select-elements: ${({ disabled }) =>
    disabled ? '' : 'var(--color-field-filter-placeholder)'};
  --color-select-elements: ${({ value, disabled }) =>
    isNotEmptyValue(value) && !disabled
      ? 'var(--color-field-primary)'
      : 'var(--color-empty-select-elements)'};

  width: 100%;

  &&& {
    & > div:first-of-type {
      border: ${({ value, disabled }) =>
        isNotEmptyValue(value) && !disabled
          ? '1px solid var(--color-field-filled-stroke)'
          : '1px solid var(--color-elements-secondary)'};
    }
  }

  .ant-select-selection-item {
    font-size: 12px;
    font-weight: ${({ mode }) => (mode === 'multiple' ? 400 : 500)};
    line-height: 1.83;
    color: ${({ mode }) =>
      mode === 'multiple'
        ? 'var(--color-text-primary-2)'
        : 'var(--color-text-primary)'};
    background-color: ${({ mode, disabled }) =>
      mode === 'multiple' &&
      !disabled &&
      'var(--color-background-additional-3)'};
    border-color: ${({ mode, disabled }) =>
      mode === 'multiple' &&
      !disabled &&
      'var(--color-background-additional-3)'};
  }

  .ant-select-item-option-active {
    color: var(--color-text-primary);
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--color-text-primary);
  }

  .ant-select-selection-item-remove {
    align-self: center;
    color: var(--color-elements-additional);

    &:hover {
      color: var(--color-elements-type-secondary);
    }
  }

  .ant-select-selection-placeholder {
    font-size: 12px;
    line-height: 1.83;
    color: var(--color-select-elements);
  }

  .ant-select-arrow {
    color: var(--color-select-elements);
  }

  .ant-select-clear {
    color: var(--color-field-filled-stroke);
    &:hover {
      color: var(--color-field-filled-stroke);
    }
  }
`;
