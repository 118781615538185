import styled from '@emotion/styled';
import { Breadcrumb } from 'antd';
import { createShouldForwardProp } from '@functions';

let shouldForwardProp = createShouldForwardProp(['last']);

export let StyledBreadcrumb = styled(Breadcrumb)``;

export let StyledBreadcrumbSeparator = styled.span`
  font-size: 12px;
  color: var(--color-elements-secondary);
`;

export let StyledBreadcrumbItem = styled(Breadcrumb.Item, shouldForwardProp)<{
  last?: boolean;
}>`
  &&& {
    font-size: 12px;

    font-weight: ${({ last }) => (last ? '500' : 'normal')};
    color: ${({ last }) =>
      last
        ? 'var(--color-text-gray-primary)'
        : 'var(--color-text-gray-secondary)'};
  }
`;
