import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Table, TablePaginationConfig } from 'antd';
import { TableProps as RcTableProps } from 'rc-table/lib/Table';

interface ITableStyleProps {
  pagination?: false | TablePaginationConfig;
  dataSource?: RcTableProps<any>['data'];
}

const PaginationTextStyles = css`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.83;
  color: var(--color-text-additional);
`;

export const StyledTable = styled(Table)<ITableStyleProps>`
  &&& {
    .ant-table {
      border-radius: 6px;
    }

    .ant-table-container table > thead > tr:first-of-type > *:first-of-type {
      border-start-start-radius: 5px;
    }

    .ant-table-container table > thead > tr:first-of-type > *:last-of-type {
      border-start-end-radius: 5px;
    }

    .ant-table-tbody .ant-table-cell {
      padding: 8px 8px 8px 16px;
      font-size: 12px;
      line-height: 1.83;
      color: var(--color-text-primary-2);
      border-radius: ${({ dataSource }) => (dataSource?.length ? '0' : '6px')};
      border-bottom: ${({ dataSource }) =>
        dataSource?.length
          ? '1px solid var(--color-background-additional-main)'
          : 'none'};
    }

    .ant-table-tbody {
      tr {
        &:last-of-type {
          td {
            border-bottom: none;
          }
        }
      }
    }

    .ant-table-row:hover > .ant-table-cell {
      background: var(--color-background-additional-light);
    }

    .ant-table-content {
      border-radius: ${({ pagination }) =>
        pagination &&
        typeof pagination !== 'boolean' &&
        Boolean(pagination.total)
          ? '6px 6px 0 0'
          : '6px'};
      border-top: 1px solid var(--color-background-additional-main);
      border-right: 1px solid var(--color-background-additional-main);
      border-left: 1px solid var(--color-background-additional-main);
      border-bottom: ${({ pagination }) =>
        pagination &&
        typeof pagination !== 'boolean' &&
        Boolean(pagination.total)
          ? 'none'
          : '1px solid var(--color-background-additional-main)'};

      & {
        scrollbar-width: thin;
        scrollbar-color: var(--color-background-additional-dark)
          var(--color-background-additional-light);
      }
    }

    .ant-table-column-sorter span {
      color: var(--color-elements-additional);
    }

    .ant-table-column-sorter span .active {
      color: var(--color-elements-primary);
    }

    th {
      background-color: var(--color-background-additional-main);
      padding: 12px 16px !important;
      font-size: 12px;
      font-weight: 500;
      color: var(--color-text-main-elements);

      &:not(:first-of-type) {
        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 1px;
          height: 25%;
          transform: none;
          background-color: var(--color-elements-table-divider);
        }

        &:before {
          content: '';
          position: absolute;
          top: initial !important;
          left: 0 !important;
          bottom: 0 !important;
          transform: initial !important;
          display: block;
          width: 1px;
          height: 25% !important;
          background-color: var(--color-elements-table-divider) !important;
        }
      }

      ::before {
        display: none;
      }
    }

    .ant-table-pagination {
      margin: 0 0 16px 0;
      padding: 8px 16px;
      background-color: var(--color-background-additional-main);
      border-bottom: 1px solid var(--color-background-additional-main);
      border-left: 1px solid var(--color-background-additional-main);
      border-right: 1px solid var(--color-background-additional-main);
      border-radius: 0 0 6px 6px;
      text-align: end;

      ${PaginationTextStyles};

      .ant-select-selection-item,
      .ant-pagination-item a {
        ${PaginationTextStyles};
      }

      .ant-pagination-item-active {
        border-color: var(--color-button-primary);
        border-radius: 6px;
        background-color: var(--color-background-additional-dark);
      }

      .ant-pagination-item-link,
      .ant-select-arrow,
      .ant-pagination-item-link-icon {
        color: var(--color-elements-primary);
      }

      .ant-pagination-disabled .ant-pagination-item-link {
        color: var(--color-button-disable-2);
      }

      .ant-select-selector,
      .ant-pagination-options-quick-jumper input {
        border-color: var(--color-field-default-stroke);
        border-radius: 6px;
      }
    }
  }
`;
