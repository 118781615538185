import { DatePicker } from 'antd';
import styled from '@emotion/styled';
import { Dayjs } from 'dayjs';
import { SwapRightOutlined } from '@ant-design/icons';
import { createShouldForwardProp } from '@functions';

interface IStyledRangePickerProps {
  hasValue: boolean;
}

interface IStyledDatePickerProps {
  value?: string | null | Dayjs;
  hasValue?: boolean;
}

let shouldForwardProp = createShouldForwardProp(['hasValue', 'value']);

export let StyledRangePicker = styled(
  DatePicker.RangePicker,
  shouldForwardProp,
)<IStyledRangePickerProps>`
  &&& {
    border: 1px solid
      ${({ hasValue }) =>
        hasValue
          ? 'var(--color-field-primary)'
          : 'var(--color-field-filter-placeholder)'};

    input {
      ::placeholder {
        color: var(--color-field-filter-placeholder);
      }
    }

    .ant-picker-suffix,
    .ant-picker-separator,
    .ant-picker-active-bar {
      color: ${({ hasValue }) =>
        hasValue
          ? 'var(--color-field-primary)'
          : 'var(--color-field-filter-placeholder)'};
    }

    .ant-picker-input input {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
      color: var(--color-field-primary);
    }
  }
`;

export let StyledDatePicker = styled(
  DatePicker,
  shouldForwardProp,
)<IStyledDatePickerProps>`
  &&& {
    border: 1px solid
      ${({ value, hasValue }) =>
        Boolean(value) || Boolean(hasValue)
          ? 'var(--color-field-filled-stroke)'
          : 'var(--color-elements-secondary)'};

    .ant-picker-suffix {
      color: ${({ value, hasValue }) =>
        value || Boolean(hasValue)
          ? 'var(--color-field-filled-stroke)'
          : 'var(--color-field-filter-placeholder)'};
    }

    .ant-picker-input input {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.83;
      color: var(--color-text-primary);
    }

    .ant-picker-input input::placeholder {
      color: var(--color-field-filter-placeholder);
      font-weight: 400;
    }
  }
`;

export let StyledWrapper = styled(
  'div',
  shouldForwardProp,
)<IStyledDatePickerProps>`
  display: grid;
  grid-template-columns: 1fr 32px 1fr;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid
    ${({ value, hasValue }) =>
      Boolean(value) || Boolean(hasValue)
        ? 'var(--color-field-primary)'
        : 'var(--color-field-filter-placeholder)'};
`;

export let StyledPicker = styled(
  DatePicker,
  shouldForwardProp,
)<IStyledDatePickerProps>`
  &&& {
    border: transparent;
    box-shadow: none;
    .ant-picker-suffix {
      color: ${({ value, hasValue }) =>
        value || Boolean(hasValue)
          ? 'var(--color-field-primary)'
          : 'var(--color-field-filter-placeholder)'};
    }

    .ant-picker-input input {
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.83;
      color: var(--color-field-primary);
    }

    .ant-picker-input input::placeholder {
      color: var(--color-field-filter-placeholder);
    }
  }
`;

export let StyledRangeDivider = styled(
  SwapRightOutlined,
  shouldForwardProp,
)<IStyledDatePickerProps>`
  &&& {
    font-size: 16px;
    color: ${({ hasValue }) =>
      !hasValue
        ? 'var(--color-field-filter-placeholder)'
        : 'var(--color-field-primary)'};
  }
`;
