import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { DateFormat, getSorterParamsForApi, insertIfObj } from '@functions';
import { IBaseApiRequest, IFilterByCustomer } from '@api';
import { IPaginated, IPaymentOrder, PaginationMeta } from '@entities';

export interface IGetPaymentOrdersFilters extends IPaginated {
  search?: string;
  clientId?: string;
  userId?: string;
  batchId?: string;
  accountId?: string;
  settlementJournalId?: string;
  amountGTE?: number;
  amountLTE?: number;
  createdAtGTE?: number;
  createdAtLTE?: number;
  finishedAtGTE?: number;
  finishedAtLTE?: number;
  rfiStartDate?: number;
  rfiEndDate?: number;
  types?: string[];
  bankEntityIds?: string[];
  glAccountBankEntityId?: string;
  state?: string;
  kind?: string;
  rfiStates?: string[];
  noRfi?: boolean;
  bcaStates?: string[];
  methods?: string[];
  attribute?: string;
  direction?: string;
}

interface IGetAll extends IBaseApiRequest, IFilterByCustomer {
  filters: IGetPaymentOrdersFilters;
}
export async function getAll({ api, filters, baasClientId }: IGetAll) {
  let params = {
    ...insertIfObj(Boolean(filters.search), { search: filters.search }),
    ...insertIfObj(Boolean(filters.clientId), { clientId: filters.clientId }),
    ...insertIfObj(Boolean(filters.userId), {
      user_id: filters.userId,
    }),
    ...insertIfObj(Boolean(filters.state), {
      state: filters.state,
    }),
    ...insertIfObj(Boolean(filters.kind), {
      payment_kind: filters.kind,
    }),
    // ...insertIfObj(Boolean(filters.accountId), {
    // accountId: filters.accountId,
    // }),
    ...insertIfObj(Boolean(filters.amountGTE), {
      amount_gteq: filters.amountGTE,
    }),
    ...insertIfObj(Boolean(filters.amountLTE), {
      amount_lteq: filters.amountLTE,
    }),
    ...insertIfObj(Boolean(filters.createdAtGTE), {
      created_at_gteq: dayjs
        .unix(Number(filters.createdAtGTE))
        .startOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.createdAtLTE), {
      created_at_lteq: dayjs
        .unix(Number(filters.createdAtLTE))
        .endOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.finishedAtGTE), {
      finished_at_gteg: dayjs
        .unix(Number(filters.finishedAtGTE))
        .startOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.finishedAtLTE), {
      finished_at_lteg: dayjs
        .unix(Number(filters.finishedAtLTE))
        .endOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.types?.length), {
      bcaTypes: filters.types?.filter((type) => type.includes('_')).join(','),
    }),
    ...insertIfObj(Boolean(filters.bankEntityIds?.length), {
      'q[in_bank_entity_id_in]': filters.bankEntityIds?.join(','),
    }),
    ...insertIfObj(Boolean(filters.glAccountBankEntityId), {
      glAccountBankEntityId: filters.glAccountBankEntityId,
    }),
    ...insertIfObj(Boolean(filters.methods?.length), {
      methods: filters.methods?.join(','),
    }),
    ...insertIfObj(Boolean(filters.bcaStates?.length), {
      'q[bca_state_in]': filters.bcaStates?.join(','),
    }),
    ...insertIfObj(Boolean(filters.rfiStates?.length), {
      'last_rfi[states]': filters.rfiStates?.join(','),
    }),
    ...insertIfObj(Boolean(filters.rfiStartDate), {
      'last_rfi[created_at_from]': dayjs
        .unix(Number(filters.rfiStartDate))
        .startOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.rfiEndDate), {
      'last_rfi[created_at_to]': dayjs
        .unix(Number(filters.rfiEndDate))
        .endOf('day')
        .format(DateFormat.DATE_FILTER_WITH_TIME_ET),
    }),
    ...insertIfObj(Boolean(filters.settlementJournalId), {
      wiseSettlementJournalId: filters.settlementJournalId,
    }),
    ...insertIfObj(Boolean(filters.noRfi), {
      noRfi: filters.noRfi,
    }),
    offset: filters.offset,
    limit: filters.limit,
    ...getSorterParamsForApi({
      attribute: filters.attribute,
      direction: filters.direction,
    }),
  };

  let response = (await api.get(`bank/${baasClientId}/display_transactions`, {
    params,
  })) as AxiosResponse<{
    data: {
      data: IPaymentOrder[];
      meta: PaginationMeta;
    };
  }>;

  return response.data.data;
}

interface IGetOne extends IBaseApiRequest, IFilterByCustomer {
  transactionId: string;
}
export async function getOne({ api, transactionId, baasClientId }: IGetOne) {
  let response = (await api.get(
    `bank/${baasClientId}/display_transactions/${transactionId}`,
  )) as AxiosResponse<{
    data: IPaymentOrder;
  }>;

  return response.data.data;
}
