import { Tabs } from 'antd';
import styled from '@emotion/styled';

export let StyledTabs = styled(Tabs)`
  &&& {
    font-size: 14px;
    line-height: 1.71;
    color: var(--color-elements-additional-2);

    .ant-tabs-nav {
      margin: 0 0 12px 0;

      &:before {
        border-bottom: 1px solid var(--color-background-additional-2);
      }
    }

    .ant-tabs-tab {
      padding: 6px 0;
    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--color-elements-additional-3);
    }

    .ant-tabs-tab-btn {
      & > span {
        display: grid;
        grid-auto-flow: column;
        grid-column-gap: 8px;
      }
    }

    .ant-tabs-tab-active .anticon {
      color: var(--color-elements-additional-3);
    }

    .ant-tabs-tab .anticon {
      margin-right: 0;
    }

    .ant-tabs-ink-bar {
      background-color: var(--color-elements-additional-3);
    }
  }
`;
