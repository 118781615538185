import styled from '@emotion/styled';
import { Form } from 'antd';

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    padding: 0 0 4px 0;

    & > label {
      font-size: 12px;
      font-weight: 500;
      color: var(--color-field-title);
    }
  }

  .ant-form-item-explain-error {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.83;
    color: var(--color-status-error-2);
  }
`;
