export const ROUTES = {
  users: '/users',
  userProfile: '/users/:id',

  clients: '/clients',
  clientProfile: '/clients/:id',

  accounts: '/accounts',
  accountProfile: '/accounts/:id',

  transactions: '/transactions',
  transactionProfile: '/transactions/:id',

  contacts: '/contacts',
  contactProfile: '/contacts/:id',

  ezInvites: '/ez-invites',
  ezInviteProfile: '/ez-invites/:id',

  ezRoles: '/ez-roles',
  ezRoleProfile: '/ez-roles/:id',

  ezUsers: '/ez-users',
  ezUserProfile: '/ez-users/:id',
};
